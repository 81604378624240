import styled, { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle([".ant-popover-inner{", "}"], {
  "padding": "0px !important"
});
export const Global = {
  WysiwygContent: styled.div.withConfig({
    displayName: "GlobalStyle__WysiwygContent",
    componentId: "sc-1dc97oq-0"
  })(["*,&{", " font-family:'DB Heavent' !important;white-space:initial !important;word-break:break-word !important;a{", "}ol{counter-reset:list-number;li{counter-increment:list-number;}}ol li::before{content:counter(list-number,decimal) '. ';", "}ul li::before{content:'\u2022';", "}}"], {
    "maxWidth": "100% !important",
    "fontSize": "1.5rem !important",
    "lineHeight": "30px !important",
    "--tw-text-opacity": "1 !important",
    "color": "rgb(88 88 88 / var(--tw-text-opacity)) !important"
  }, {
    "--tw-text-opacity": "1 !important",
    "color": "rgb(59 130 246 / var(--tw-text-opacity)) !important"
  }, {
    "paddingRight": "0.25rem"
  }, {
    "paddingRight": "0.5rem"
  })
};
export default GlobalStyle;