import { NoticeType } from 'antd/es/message/interface';
import { create } from 'zustand';
export interface LoadingState {
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
}
export const loadingStore = create<LoadingState>(set => ({
  isLoading: false,
  setIsLoading: (loadingState: boolean) => set({
    isLoading: loadingState
  })
}));
export interface MessageUi {
  text: string;
  status: NoticeType;
  duration?: number;
}
const initialMessageUiState: MessageUi = {
  text: '',
  status: 'info',
  duration: 3
};
export const messageStore = create<{
  messageInfo: MessageUi;
  setMessageInfo: (val: MessageUi) => void;
  clearMessageInfo: () => void;
}>(set => ({
  messageInfo: initialMessageUiState,
  setMessageInfo: (messageInfoState: MessageUi) => set({
    messageInfo: messageInfoState
  }),
  clearMessageInfo: () => set({
    messageInfo: initialMessageUiState
  })
}));